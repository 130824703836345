import axios from "axios";
import { environment } from "../environments/environment";

async function login(data) {
  return axios.post(environment.API_URL + "auth/login", data);
}
async function checkAuth() {
  return axios.get(environment.API_URL + "auth/check");
}
async function logout() {
  return axios.post(environment.API_URL + "auth/logout");
}

export { login, checkAuth, logout };
