import { useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import "../../assets/css/global.css";
import { Pencil } from "react-bootstrap-icons";
import AdherentService from "../../../services/adherent.service";
import { periodeSubscription } from "../../../utils/variables/periodeSubscription";
import SelectForm from "../../lib/select/select";
const validationRules = {
  firstname: "required|alpha",
  lastname: "required|alpha",
  email:
    "required|email|regex:/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/",
  phone: "required|digits:8",
  username: "required",
  password: "required|string|min:8",
  confirmedPassword: "required|same:password",
};

const adherentService = new AdherentService();

export default function AddAdherent({
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmedPassword: "",
    avatar: "",
    period: "",
  });
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});
  const [imageUrl, setImageUrl] = useState(
    "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
  );

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }

  const CloseModalAddAdherent = () => {
    closeModalFunction();
  };
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    password: "mot de passe",
    confirmedPassword: "confirmer mot de passe",
  });
  async function generateUsername() {
    try {
      if (userData.lastname && userData.firstname !== "") {
        const newUSerName = await adherentService.generateUserName(userData);
        setUserData((prevState) => ({
          ...prevState,
          username: newUSerName.data.username,
        }));
      }
    } catch (error) {
      console.error("Error generating username:", error);
    }
  }

  async function addAdherent() {
    if (validation.passes()) {
      const newAdherent = new FormData();
      newAdherent.append("avatar", userData.avatar);
      newAdherent.append("firstname", userData.firstname);
      newAdherent.append("lastname", userData.lastname);
      newAdherent.append("email", userData.email);
      newAdherent.append("phone", userData.phone);
      newAdherent.append("username", userData.username);
      newAdherent.append("password", userData.password);
      newAdherent.append("confirmedPassword", userData.confirmedPassword);
      newAdherent.append("period", userData.period);

      try {
        await adherentService.addAdherent(newAdherent);
        showAlert({
          title: "Adhérent ajouté avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddAdherent();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        avatar: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        onCancelFunction={CloseModalAddAdherent}
        onCloseFunction={CloseModalAddAdherent}
        submitFunction={addAdherent}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Adhérents"
            href="/adherents"
            activatedLinkTitle="Créer Un adhérent"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="imageUpload">
                    <Pencil className="edit_avatar_img" />
                  </label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                classNames="form-control-input-group-add"
                title="Nom"
                type="text"
                name="lastname"
                value={userData.lastname}
                onChangeFunction={handleInputChange}
                onBlurFunction={generateUsername}
              />
              {validationErrors && validationErrors.lastname && (
                <AlertMessages
                  message={validationErrors.lastname}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Prénom"
                type="text"
                name="firstname"
                value={userData.firstname}
                onChangeFunction={handleInputChange}
                onBlurFunction={generateUsername}
              />
              {validationErrors && validationErrors.firstname && (
                <AlertMessages
                  message={validationErrors.firstname}
                  messageType="error"
                />
              )}
            </div>
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                onChangeFunction={handleInputChange}
                name="phone"
              />
              {validationErrors && validationErrors.phone && (
                <AlertMessages
                  message={validationErrors.phone}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                onChangeFunction={handleInputChange}
                name="email"
              />
              {validationErrors && validationErrors.email && (
                <AlertMessages
                  message={validationErrors.email}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                name="username"
                readOnly
              />
              {validationErrors && validationErrors.username && (
                <AlertMessages
                  message={validationErrors.username}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Mot de passe"
                type="password"
                value={userData.password}
                onChangeFunction={handleInputChange}
                name="password"
              />
              {validationErrors && validationErrors.password && (
                <AlertMessages
                  message={validationErrors.password}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Confirmer mot de passe"
                type="password"
                value={userData.confirmedPassword}
                onChangeFunction={handleInputChange}
                name="confirmedPassword"
              />
              {validationErrors && validationErrors.confirmedPassword && (
                <AlertMessages
                  message={validationErrors.confirmedPassword}
                  messageType="error"
                />
              )}
              <SelectForm
                onChangeFunction={handleInputChange}
                options={periodeSubscription}
                name="period"
                value={userData.period}
                labelFilter="Abonnement "
                classNames="addUser_select mt-1"
                selectStyle="select_subscription_style"
                titleSelect="Abonnement (Mois)"
              />
            </div>
          </div>
        }
      />
    </>
  );
}
