import { CheckCircle, Pen, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import TableContent from "../lib/table/table";
import { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import MenuContent from "../lib/Menu/menu";
import InputGroup from "../lib/input-group/input-group";
import { statusOptions } from "../../utils/variables/statusOptions";
import SalleService from "../../services/salle.service";
import { villes } from "../../utils/variables/villesOptions";
import AddSalle from "../components/salle/addSalle";
import EditSalle from "../components/salle/editSalle";
import DetailsSalle from "../components/salle/detailsSalle";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import { environment } from "../../environments/environment";
import CoachService from "../../services/coach.service";
import { statusSalleOptions } from "../../utils/variables/salleFilterStatus";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};
const columns = [
  "",
  "Nom",
  "Région",
  "Statuts",
  "Téléphone",
  "Username",
  "Nombre d’abonnement",
  "Chiffre",
  "",
];

export default function Salles() {
  const salleService = new SalleService();
  const userService = new UserService();
  const coachService = new CoachService();
  const baseUrl = environment.API_URL;
  const navigate = useNavigate();
  const [originalSalles, setOriginalSalles] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    city: "",
    status: "",
    state: "",
    search: "",
  });

  const [openModal, setOpenModal] = useState("");
  useEffect(() => {
    getAllSalles();
  }, []);

  useEffect(() => {
    filter();
    console.log(originalSalles);
  }, [filterOptions]);
  const filter = () => {
    let filtredData = originalSalles;
    if (filterOptions.status !== "") {
      filtredData = filtredData.filter(
        (salle) =>
          salle[3].props.children.props.children[0] === filterOptions.status
      );
    }
    if (filterOptions.city !== "") {
      filtredData = filtredData.filter(
        (salle) => salle[2] === filterOptions.city
      );
    }
    if (filterOptions.search !== "") {
      filtredData = filtredData.filter((salle) => {
        return (
          salle &&
          JSON.stringify(salle)
            .toLowerCase()
            .includes(filterOptions.search.toLowerCase())
        );
      });
    }
    setUsers(filtredData);
  };

  function search(searchQuery) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: searchQuery,
      };
    });
  }

  const getAllSalles = async () => {
    try {
      await salleService
        .getAllSalles()
        .then((response) => {
          const data = response.data.map((salle) => formatUserData(salle));
          setUsers(data);
          setOriginalSalles(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const formatUserData = (salle) => {
    const menuOptions = [
      <div>
        <Button
          title={"modifier"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <EditSalle
                closeModalFunction={closeModal}
                onSuccessFunction={getAllSalles}
                salle={salle}
              />
            );
          }}
        />
      </div>,
      <Button
        title={"désactiver"}
        classNames=" bg-white border-0 itemBtn"
        onClickFunction={() => toggleSalleStatus(salle._id)}
      />,
      <div>
        <Button
          title={"détails"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <DetailsSalle closeModalFunction={closeModal} salle={salle} />
            );
          }}
        />
      </div>,
    ];
    const statusTextClass =
      salle.status === "enabled"
        ? "Actif"
        : salle.status === "paused"
          ? "En pause"
          : "";
    const statusClass =
      salle.status === "enabled"
        ? "status_enabled"
        : salle.status === "paused"
          ? "status_paused"
          : "";
    const StatusMenuOptions = [
      <div>
        <Button
          title={"Actif "}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => updateStatusSalle(salle._id, "enabled")}
        />
      </div>,
      <div>
        <Button
          title={"En pause"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => updateStatusSalle(salle._id, "paused")}
        />
      </div>,
    ];
    return [
      <img
        src={baseUrl + "users/" + salle._id + "/image"}
        className="default_salle_img"
      />,
      salle.firstname,
      salle.city,
      <span className={statusClass}>
        <div className="d-flex align-items-center">
          {statusTextClass}{" "}
          <MenuContent
            btnContent={<Pen className="icon_status" />}
            menuItemOptions={StatusMenuOptions}
          />
        </div>
      </span>,
      salle.phone,
      salle.username,
      <div className="text-center">{salle.ssub.length ?? 0}</div>,
      <div className="text-center">{salle.amount}Dt</div>,
      <MenuContent
        btnContent={<CheckCircle className="icon_actions" />}
        menuItemOptions={menuOptions}
      />,
    ];
  };

  const filteringStatus = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        status: value,
      };
    });
  };
  const filteringCity = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        city: value,
      };
    });
  };
  const closeModal = () => {
    setOpenModal("");
  };

  const resetFilters = () => {
    setFilterOptions({
      city: "",
      status: "",
      state: "",
      search: "",
    });
  };
  const toggleSalleStatus = async (userId) => {
    try {
      showAlert({
        title: "vous êtes sûr ?",
        iconType: "question",
        confirmButtonText: "oui",
        cancelButtonText: "Non",
        showCancelButton: true,
        titleSuccess: "Agent désactivée avec succès",
        iconSuccess: "success",
        confirmFunction: async () => {
          await userService.disableUser(userId);
          return navigate("/archive");
        },
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const updateStatusSalle = async (userId, newStatus) => {
    try {
      showAlert({
        title: "vous êtes sûr ?",
        iconType: "question",
        confirmButtonText: "oui",
        cancelButtonText: "Non",
        showCancelButton: true,
        titleSuccess: "Statuts modifiée avec succées",
        iconSuccess: "success",
        confirmFunction: async () => {
          await coachService.updateStatus(userId, {
            status: newStatus,
          });
          getAllSalles();
        },
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="me-2">
        <div className="fw-bolder fs-4 pt-3">
          <a href="/agents" className="text-decoration-none text-black">
            Agents
          </a>
        </div>
        <div>
          <InputGroup
            classNames="form-control-input-group-search "
            type={"text"}
            placeholder="Recherche"
            iconStart={<Search />}
            input_style="inputStyleSearch"
            value={filterOptions.search}
            onChangeFunction={search}
          />
        </div>
        <div className="d-flex align-items-center mt-3">
          <div className="d-flex justify-content-start align-items-center pe-5">
            <SelectForm
              onChangeFunction={filteringCity}
              options={villes}
              MenuProps={MenuProps}
              value={filterOptions.city}
              labelFilter="région"
              classNames="filtered_select me-3"
              selectStyle="label_select_status"
            />
            <SelectForm
              onChangeFunction={filteringStatus}
              options={statusSalleOptions}
              value={filterOptions.status}
              labelFilter="Statuts"
              classNames="filtered_select "
              selectStyle="label_select_status"
            />
          </div>
          <div>
            <Button
              title={"Effacer filtres"}
              color="secondary"
              classNames="clearFilterButton p-0"
              onClickFunction={resetFilters}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            title={"Ajouter"}
            color="success"
            classNames="add_button"
            iconStart={<PlusCircle className="icon_add" />}
            onClickFunction={() => {
              setOpenModal(
                <AddSalle
                  closeModalFunction={closeModal}
                  onSuccessFunction={getAllSalles}
                />
              );
            }}
          />
        </div>
        <TableContent
          columns={columns}
          data={users}
          rowsPerPage={5}
          totalRows={users.length}
        />
        {openModal}
      </div>
    </>
  );
}
